import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react'
import './App.css'
import { Home } from '../src/pages/Home'
import { MedicineDelivery } from '../src/pages/MedicineDelivery'
import { AboutUs } from '../src/pages/AboutUs'
import { Services } from '../src/pages/Services'
import { Contact } from '../src/pages/Contact'
import { Navbar } from '../src/shared/Navbar'
import { Error } from '../src/pages/Error'
import Footer from '../src/shared/Footer'
import { Helmet } from 'react-helmet'
import PackingShipment from './pages/PackingShipment'

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>
          International Courier Services in Hyderabad[Call:8125911887]
        </title>
        <meta
          name='description'
          content='Medicine delivery service, free home pickup, reasonable and reliable courier services, courier services in Hyderabad, send medicines to anywhere in the world'
        />
      </Helmet>
      <Navbar />
      <div className='container'>
        <Routes>
          <Route path='/' element={<Home></Home>}></Route>
          <Route path='about-us' element={<AboutUs />}></Route>
          <Route path='services' element={<Services />}></Route>
          <Route
            path='medicine-delivery'
            element={<MedicineDelivery />}
          ></Route>
          <Route path='contact' element={<Contact />}></Route>
          <Route path='packingshipment' element={<PackingShipment />}></Route>
          <Route path='*' element={<Error />}></Route>
        </Routes>
      </div>
      <Footer></Footer>
    </BrowserRouter>
  )
}

export default App
