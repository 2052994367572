import React from 'react'
const PackingShipment = () => {
  return (
    <div>
      <h1>Packing your shipment</h1>
      <p>
        When you are ready to ship your product, it is important to take the
        time to properly pack your shipment. This will help to ensure that your
        product arrives safely and in good condition. There are a few things to
        keep in mind when packing your shipment: -Choose the right box. Make
        sure to use a sturdy box that is the appropriate size for your product.
        -Pack your product securely. Use packing material such as bubble wrap or
        packing peanuts to help keep your product safe. -Include all necessary
        information. Be sure to include a shipping label with your name,
        address, and contact information. By taking the time to properly pack
        your shipment, you can help to ensure that your product arrives safely
        and in good condition.
      </p>
      <p>
        There are a few things you should take into account when sending a
        parcel internationally. The first is the size and weight of the parcel -
        make sure you are aware of the restrictions of the country you are
        sending to. The second is the value of the contents of the parcel -
        declare this on the customs form, and include insurance if the value is
        high. The third is the delivery time - sending by express service will
        be quicker, but will cost more. Finally, make sure you have the correct
        address and postcode for the recipient.
      </p>
    </div>
  )
}
export default PackingShipment
