import React from 'react'
import { Helmet } from 'react-helmet'

export const Contact = () => {
  return (
    <main>
      <Helmet>
        <title>Send parcel international cheap, call +91 8125911887</title>
        <meta
          property='og:title'
          content='Send parcel international cheap, call +91 8125911887'
        />
        <meta
          property='description'
          content='dhl international courier services from Hyderabad, door to door quick pickup and delivery service, contact us for more info'
        />
        <meta
          property='og:description'
          content='dhl international courier services from Hyderabad, door to door quick pickup and delivery service, contact us for more info'
        />
        <link rel='canonical' href='contact' />
      </Helmet>
      <h1>Contact us</h1>
      <div className='contact-info'>
        <div>
          <h4 className='pre-title'>Email us at</h4>
          <p>
            <a href='mailto:info@fastestcourierservice.com'>
              info@fastestcourierservice.com
            </a>
          </p>
        </div>
        <div>
          <h4 className='pre-title'>Call us at</h4>
          <p>
            <a href='https://api.whatsapp.com/send?phone=+918125911887'>
              +91- 8125911887
            </a>
          </p>
        </div>
        <div>
          <h4>Find us at</h4>
          <p>
            S-NO 8-1-284/OU/691/SF
            <br />
            Near Dream Valley, OU colony
            <br />
            Manikonda, Hyderabad
            <br />
          </p>
        </div>
      </div>
    </main>
  )
}
