import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer-container'>
      <div className='footer-content'>
        <div className='footer-col'>
          <h2>Fastest Courier Services</h2>
          <address>
            S-NO 8-1-284/OU/691/SF
            <br />
            Near Dream Valley, OU colony
            <br />
            Manikonda, Hyderabad - 500089,
            <br /> Telangana state, India
          </address>
        </div>
        <div className='footer-col'>
          <h2>Links</h2>
          <nav className='footer-navigation'>
            <NavLink to='/'>Home</NavLink>
            <NavLink to='/about-us'>About us</NavLink>
            <NavLink to='/services'>Services</NavLink>
            <NavLink to='/contact'>Contact</NavLink>
            <NavLink to='/medicine-delivery'>Medicine delivery</NavLink>
          </nav>
        </div>
        <div className='footer-col'>
          <h2>Contact Us</h2>
          <ul className='footer-list'>
            <li>
              <a href='tel:+918125911887'>+91 8125911887</a>
            </li>
            <li>
              <a href='tel:+919542179291'>+91 9542179291</a>
            </li>
            <li>
              <a href='mailto:info@fastestcourierservie.com'>
                info@fastestcourierservice.in
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='footer-content'>
        <small>
          &copy; Fastest international Courier Services
          <time dateTime={new Date().getFullYear()}>
            {new Date().getFullYear()}
          </time>
          . All rights reserved
        </small>
      </div>
    </footer>
  )
}

export default Footer
