import React from 'react'
import { Helmet } from 'react-helmet'

export const AboutUs = () => {
  return (
    <section>
      <Helmet>
        <title>Best courier for international parcel delivery</title>
        <meta
          property='og:title'
          content='Best courier for international parcel delivery'
        />
        <meta
          property='description'
          content='Fastest courier service, leading International courier service provider in Hyderabad, door to door quick pickup and delivery service, Allopathic Medicine parcel, Ayurvedic Medicine parcel,
Homeopathic Medicine parcel,
Unani Medicine parcel'
        />
        <meta
          property='og:description'
          content='Fastest courier service, leading International courier service provider in Hyderabad, door to door quick pickup and delivery service, Allopathic Medicine parcel, Ayurvedic Medicine parcel,
Homeopathic Medicine parcel,
Unani Medicine parcel'
        />
        <link rel='canonical' href='about-us' />
      </Helmet>
      <h1>Best courier for international</h1>
      <p>
        <strong>Fastest courier service</strong> launched the company in the
        year 2016 at Hyderabad. We are the leading International courier service
        provider in Hyderabad, we are climbing the ladder by providing the best
        services
      </p>
      <p>
        At <strong>Fastest courier service</strong> , we are building the
        industry’s most comprehensive logistics platform that is able to capture
        the digital demand of shippers in different geographies, draw
        prospective clients and identify the best logistics solutions for their
        shipping needs. Our services match the international courier delivery
        standards, includes timely door-to-door pickup and courier delivery for
        major destinations across the world.
      </p>
      <p>
        Our people work with another company to get shipments delivered to
        customers on time by identifying the problem requiring to discover
        connections, we determine the appropriate schedule changes to get the
        parcel reach our customers on time.
      </p>
      <p>
        We offer different combinations of transport, to meet the demand of
        shipments, as well as for dedicated shipping for different logistics
        flows. Our offers enable us to ensure the distinct requirements related
        to the date and time of a needed shipment is fulfilled, as well as the
        use of the necessary handling equipment.
      </p>
      <p>
        We are the most reasonable and lowest charging international courier
        service providers when compared to our competitors.
      </p>
      <h2>why choose fastest courier services?</h2>
      <ul className='list'>
        <li>free home pickup</li>
        <li>door to door quick pickup and delivery service</li>
        <li>Your packages are well packaged and will arrive safely.</li>
        <li>we pickup the pacel from any location in the city and outskirts</li>
        <li>
          whatever may be your requirements , we can handle them and make you
          satisfied
        </li>
        <li>We create fastest connections to the rest of the world</li>
        <li>our customers and their satisfaction is our top priority</li>
      </ul>
      <h2>Our branches are at</h2>
      <p>Shaikpet - Malakpet - Kothapet - Vanasthalipuram</p>
      <h2>Food Items</h2>
      <p>
        we send a wide range of food packages containing variety of food
        prodcuts like snacks , pickles, eatables, groceries from hyderabad to
        any part of the world
      </p>
      <h2>List of food items that can be sent through our service</h2>
      <ul className='list'>
        <li>Gifts, cloths, sweaters</li>
        <li>Dresses, dress materials, garments, sarees, suits etc</li>
        <li>
          Home Appliances, Electronic goods, Grinders, Mixers, Computer
          Accessories, Utensils, Toys
        </li>
        <li>Study materials, books, stationeries, </li>
        <li>All types of home made food items</li>
      </ul>
      <h2>Documents/Parcels:</h2>
      <p>
        We deliver your documents and parcels to various locations of the world,
        you can track your parcel by the tracking id provided by us once your
        connection is done
      </p>
      <h2>Medicines</h2>
      <p>
        We accept all types of medicine (Note: Doctor's Prescription and Medical
        Bill is compulsory)
      </p>
      <ul className='list mb-4'>
        <li>Allopathic Medicine</li>
        <li>Ayurvedic Medicine </li>
        <li>Homeopathic Medicine</li>
        <li>Unani Medicine </li>
        <li>Any type of Medicines</li>
      </ul>
    </section>
  )
}
