import React, { useState } from 'react'
import { FaWhatsappSquare, FaPhoneSquareAlt, FaBars } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import logo from '../assets/images/logo.png'

export const Navbar = () => {
  const [phoneMenu, setPhoneMenu] = useState(false)
  // const [closeMenu, setCloseMenu] = useState(false)
  const showMenu = () => {
    setPhoneMenu(!phoneMenu)
    const mainMenu = document.querySelector('.main-navigation')
    mainMenu.classList.toggle('show-menu')
  }

  // const closeMenu = () => {
  //   setPhoneMenu(!phoneMenu)
  //   const mainMenu = document.querySelector('.main-navigation')
  //   mainMenu.classList.toggle('show-menu')
  // }
  return (
    <header>
      <div className='header-contact'>
        <div className='header'>
          <div className='contact-numbers'>
            <span className='call-for-pickup'>Call for pick up</span>
            <a href='tel:+918125911887'>
              <FaPhoneSquareAlt />
              <span>+91 8125911887</span>
            </a>
            <a href='tel:+919542179291'>
              <FaWhatsappSquare />
              <span>+91 9542179291</span>
            </a>
            <p className='guaranteed'>Delivery in 5 Working Days.</p>
          </div>
        </div>
      </div>
      <div className='header-top'>
        <div className='header'>
          <img
            src={logo}
            className='non-mobile'
            alt=''
            width='150'
            height='90'
          />
          <div className='toggle-menu'>
            <button
              type='button'
              className='nav-toggle'
              id='nav-toggle'
              onClick={showMenu}
            >
              <FaBars />
            </button>
          </div>
        </div>
      </div>
      <div className='header-bottom'>
        <nav
          className='main-navigation'
          role='navigation'
          aria-label='main menu'
        >
          <NavLink to='/'>Home</NavLink>
          <NavLink to='/about-us'>About us</NavLink>
          <NavLink to='/services'>services</NavLink>
          <NavLink to='/contact'>contact</NavLink>
          <NavLink to='/medicine-delivery'>medicine delivery</NavLink>
        </nav>
      </div>
    </header>
  )
}
