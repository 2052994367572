import React from 'react'
import { Helmet } from 'react-helmet'
import image from '../assets/images/send-medicine-parcel.jpeg'
import dhlService from '../assets/images/medicine-delivery-service-hyderabad.jpeg'

export const MedicineDelivery = () => {
  return (
    <main>
      <Helmet>
        <title>Medicine international delivery service</title>

        <meta
          property='og:title'
          content='International Medicine courier services'
        />
        <meta
          property='description'
          content='sending the medicines abroad, Medicine delivery worldwide,
          International Medicine courier services, medicine parcel service'
        />
        <meta
          property='og:description'
          content='sending the medicines abroad, Medicine delivery worldwide,
          International Medicine courier services, medicine parcel service'
        />
        <meta property='og:locale' content='en_US' />
        <link rel='canonical' href='medicine-delivery' />
      </Helmet>
      <h1>International Medicine courier services</h1>
      <div className='image-left-content'>
        <div className='col-left'>
          <img
            src={image}
            className='medicine-service'
            alt='medicine parcel from hyderabad to worldwide'
          />
        </div>
        <div className='col-right'>
          <h2 className='nri-medicine-courier'>
            Are you looking for NRI Medicine international courier service?
          </h2>
          <p>
            We provide guaranteed medicine delivery service from India to
            abroad. Send medicine to UK, send medicine to UAE, send medicine to
            USA, send medicine to Germany, send medicine to Italy, send medicine
            to Norway, send medicine to France, send medicine to Netherlands,
            send medicine to Poland, send medicine to Singapore, send medicine
            to Malaysia, send medicine to Oman, send medicine to Qatar and send
            medicine to Saudi Arabia
          </p>
        </div>
      </div>
      <p>
        There are many places in the world where people do not have access to
        the medicine they need. This can be for many reasons, such as the
        country not having the infrastructure to produce the medicine, or the
        medicine not being available in that country. That's where our medicine
        delivery service comes in. We can send medicine to anywhere in the
        world, no matter how remote the location. You can send medicine to USA,
        send ayurvedic medicine to USA, send homeopathic medicine to USA,
        medicines shipping to USA at lowest cost, we have a team of experienced
        professionals who are dedicated to making sure that people in need get
        the medicine they need. We are committed to providing a service that is
        fast, reliable, and cheap international courier in hyderabad. We know
        that when people need medicine, they need it fast. We also provide
        international express courier in hyderabad for express medicine delivery
        services. We also offer a money-back guarantee if you are not happy with
        our service. If you need medicine delivery to anywhere in the world,
        then please get in touch with us today. We would be more than happy to
        help you.
      </p>
      <p>
        Medical and health care services are very crucial services, reaching the
        medicine on time could save the life of the recipient or near and dear
        loved ones. We take very special care in sending the medicines abroad,
        by choosing right airways specially which delivers the medicine on or
        before given time. We are specialist in medicine international courier
        services to people staying abroad.
      </p>
      <p>
        Because of our cared services we have number of customers in twin cities
        who regularly send the medicine parcels through our services, we are
        thankful to them for their trust in us and our delivery boys who gets
        hurry up in getting the medicine parcels connected as soon as possible.
        As the connection is done immediately the tracking number is sent to the
        customer through which they can track their life saving parcel online
      </p>
      <h2>Medicine delivery worldwide</h2>
      <div className='image-right-content'>
        <div className='col-left'>
          <p>
            We provide medicine courier service to more than 200+ countries. We
            have a dedicated service to make medicines courier reach from India
            to any part of the world. We provide NRI international medicine
            courier services from Hyderabad to abroad. We assure that your
            medicine parcel will reach the destination before the committed
            time. If your medicine parcel require extra care of packing with
            bubble wrap, it will be taken care by us during the packing of your
            parcel and we do not extra charge you for that. We have free pickup
            services in Hyderabad and Secunderabad, just give a call to confirm,
            our executive will reach you at your location with required packing
            material. Please keep all the documents ready before our executive
            reaches you.
          </p>
        </div>
        <div className='col-right'>
          <img
            src={dhlService}
            className='medicine-service'
            alt='medicine delivering quickly and with care'
          />
        </div>
      </div>
    </main>
  )
}
